// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//ng serve --configuration=ob

import { Environment } from 'src/app/shared/types/environment.type';

export const environment: Environment = {
  code: 'ob',
  loginBackgroundImage: 'LoginBackground.jpg',
  loginLogoImage: 'TeamManager.png',
  appIcon: 'app_icon.png',
  hostUrl: 'https://ob-api.manydigital.com/',
  production: true,
  clubName: 'Odense Boldklub',
  teamId: 'dbl7ir1qugqz8nvp1vzn8im50',
  primaryColor: '#005192',
  secondaryColor: '#ffc400',
  pushDefaultLanguage: 'da',
  supportedLanguages: ['da'],
  preferredLanguage: 'da',
  firebaseConfig: {
    apiKey: "AIzaSyBmFRNXb7A3w6PAhsboN8TcrBLlKMDK8TU",
    authDomain: "md-ob-app.firebaseapp.com",
    projectId: "md-ob-app",
    storageBucket: "md-ob-app.appspot.com",
    messagingSenderId: "926611391739",
    appId: "1:926611391739:web:0ac11ce4852a443b98dc5e",
    measurementId: "G-L5XYFD9H3D"
  },
};


export const richTextConfig = {
  style: {
    fontFamily: 'DINPro-Black, sans-serif',
    fontWeight: 400,
    textAlign: 'center',
    font: '16px DINPro-Black',
  },
  font: {
    list: {
      'DINPro-Black': 'DINPro-Black',
    },
  },
};

export const firebaseConfig = {
  apiKey: "AIzaSyBmFRNXb7A3w6PAhsboN8TcrBLlKMDK8TU",
  authDomain: "md-ob-app.firebaseapp.com",
  projectId: "md-ob-app",
  storageBucket: "md-ob-app.appspot.com",
  messagingSenderId: "926611391739",
  appId: "1:926611391739:web:0ac11ce4852a443b98dc5e",
  measurementId: "G-L5XYFD9H3D"
};
